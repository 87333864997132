@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  font-family: 'IBM Plex Sans Condensed', sans-serif !important;
  font-family: 'Lato', sans-serif !important;
  font-family: 'Montserrat Alternates', sans-serif !important;
  font-family: 'Poppins', sans-serif !important;
}




.herox{
  position: relative;
  background-image: url("https://www.exabeam.com/wp-content/uploads/BLOG-21-Top-Cybersecurity-Threats-and-How-Threat-Intelligence-Can-Help-featured-01.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.herox::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}
.herox::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(15, 20, 25, 0) 0%, rgba(15, 20, 25, 0.6) 100%);
}

.shadow__box{
  border-bottom: 1px solid #007FFF;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(6, 141, 232, 0.5);    ;
}


.border__b{
  border: #1b9bf0 solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
}

.button-57{
  background-color: #007FFF;
  color: white;
  padding: 10px;
  border-radius: 10px;
  transition: ease-in-out 300ms;
}

.button-57:hover{
  background-color: white;
  color: #007FFF;

}

.button-58{
  background-color: #fff;
  color: #007FFF;
  padding: 10px;
  border-radius: 10px;
  border: solid #007FFF;
  transition: ease-in-out 300ms;
}

.button-58:hover{
  background-color: #007FFF;
  color: white;
}

.border__Top{
  border-top: solid #007FFF 2px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
}


.numbers{
  position: relative;
  background-image: url("https://www.shutterstock.com/image-photo/two-colleagues-working-together-protect-600nw-2013901751.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content; 
}

.numbers::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); 
}

.Button__{
  border: #007FFF solid 1px;
  background: rgba(0, 127, 255, 0.2);
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 127, 255, 1);
  padding: 9px;
  margin-top: 10px;
  color: white;
  transition: 300ms ease-in-out;
}



.hero {
  position: relative;
  background-image: url('https://www.ust.com/content/dam/ust/images/people/team-in-control-center-people.jpeg?auto=webp,smallest&quality=80');
  background-size: cover;
  background-position: center;
  height: 300px; /* You can adjust the height as needed */
}

.hero::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
}

.hero-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  color: white;
  z-index: 1; /* Make sure the text and button are above the pseudo-element */
}

.hero-content h2 {
  font-size: 2rem;
  font-weight: bold;
}

.hero-content p {
  font-size: 1.2rem;
  margin-bottom: 1rem;
}

.contact-btn{
  background-color: #0779e4;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  border-radius: 10px;
}



.Bitcoin {
  position: relative;
  background-image: url("https://www.europeanbusinessreview.com/wp-content/uploads/2021/04/pexels-photo-5831260.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Bitcoin::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Bitcoin::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Romance {
  position: relative;
  background-image: url("https://media.istockphoto.com/id/177314625/photo/beautiful-young-girl-with-a-tear-on-her-cheek.jpg?s=612x612&w=0&k=20&c=-mj2YBLfAGcXjfEPPmSuHh4O4m0ftvHrV9uS1l8qvgM=");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Romance::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Romance::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Social{
  position: relative;
  background-image: url("https://img.freepik.com/premium-photo/smiling-young-asian-woman-glasses-typing-tablet-has-romantic-chat-with-hearts-neon-background_116547-50310.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}



.Social::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
}

/* Add a gradient overlay for the bottom part */
.Social::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}



.Blackmail{
  position: relative;
  background-image: url("https://www.eezlaw.com/images/blog/shutterstock_701124811.jpg");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Blackmail::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Blackmail::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Phone{
  position: relative;
  background-image: url("https://preview.redd.it/ebf34exdtv651.jpg?auto=webp&s=64455272f0bc36fc648e1c63d19f1381842d2172");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Phone::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Phone::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}


.Account{
  position: relative;
  background-image: url("https://images.unsplash.com/photo-1636487658611-5f3a503c0173?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTl8fGdhbWluZ3Jvb218ZW58MHwwfDB8fHww&w=1000&q=80");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Account::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Account::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}




.About{
  position: relative;
  background-image: url("https://www.ust.com/content/dam/ust/images/people/team-in-control-center-people.jpeg?auto=webp,smallest&quality=80");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.About::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.About::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}



.Contact{
  position: relative;
  background-image: url("https://www.ust.com/content/dam/ust/images/people/team-in-control-center-people.jpeg?auto=webp,smallest&quality=80");
  background-size: cover;
  background-position: center;
  height: fit-content;
  background-color: rgba(0, 49, 132, 0.6); /* Match the color of the bottom part of your image */
}


.Contact::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
}
.Contact::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 30%; /* Adjust the height as needed */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.6) 100%);
}
